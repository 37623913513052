import { Link } from "gatsby"
import { ImgixGatsbyImage } from "@imgix/gatsby"

import { LandingNav } from "../components/nav"
import { Article } from "../components/containers"
import Seo from "../components/Seo"

import { useSiteNavigation } from "../hooks/use-site-navigation"
import { useProsePosts } from "../hooks/use-prose-posts"

const HomePage = () => {
  const { allChapters } = useProsePosts()
  const chapter = allChapters[allChapters.length - 1].node
  const { landingMenu } = useSiteNavigation()

  return (
    <>
      <Seo pageType="home" />
      <Article semantic={false}>
        <Link to="/story/">
 <ImgixGatsbyImage
          src="https://mirverse.imgix.net/logoTiny.png"
          layout="constrained"
          alt="The Bitter Drop — logo in a decorative blackletter typeface."
          //width={1100}
          // Set either
          // aspectRatio={16 / 9}
          // or
          sourceWidth={1100}
          sourceHeight={575}
          sx={{
            display: "block",
            width: ["94%", "auto"],
            maxWidth: ["1100px"],
            margin: "2rem auto 3rem"
          }}
        />
        </Link>
        <LandingNav chapter={chapter.slug} navigation={landingMenu} />
      </Article>
    </>
  )
}

export default HomePage
