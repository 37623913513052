import { useStaticQuery, graphql } from "gatsby"

export const useProsePosts = () => {
  const archive = useStaticQuery(graphql`
    query AllProsePostsQuery {
      allChapters: allProsePost(
        filter: { scene: { eq: "00" }, published: { eq: true } }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            slug
          }
        }
      }
      allScenes: allProsePost(
        filter: { published: { eq: true } }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            slug
          }
        }
      }
      allVolumes: allProsePost(
        filter: { scene: { eq: "99" }, published: { eq: true } }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            volume
            slug
          }
        }
      }
      volOne: allProsePost(
        filter: {
          volume: { in: ["sparrows"] }
          scene: { eq: "00" }
          published: { eq: true }
        }
        sort: { fields: order, order: ASC }
      ) {
        edges {
          node {
            id
            chapter
            title
            slug
          }
        }
      }
    }
  `)

  return {
    allChapters: archive.allChapters.edges,
    allScenes: archive.allScenes.edges,
    allVolumes: archive.allVolumes.edges,
    volOne: archive.volOne.edges
  }
}
